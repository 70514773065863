import { defineStore } from "pinia";
import { computed, ref, watch } from 'vue';
import { onSnapshot, query, Unsubscribe, where, collection } from "firebase/firestore";
import mitt from "mitt";
import { DataInstance } from "@busy-human/gearbox";
import { Entitlement } from "@busy-human/payment-module";
import { useAuth } from '@/store/auth';
import { firestore } from "@/util/firebase";

const stripeConfig:{[key: string]: string} = CONFIG.stripe;

// eslint-disable-next-line max-lines-per-function
export const useEntitlements = defineStore('entitlements', ()=>{

	const auth = useAuth();

	type Events = {
		"entitlement:confirmed": DataInstance<Entitlement.Model>,
		"entitlement:added": DataInstance<Entitlement.Model>,
		"entitlement:removed": DataInstance<Entitlement.Model>,
	}

	const emitter = mitt<Events>();

	const usersEntitlements = ref(new Map<string, DataInstance<Entitlement.Model>>());

	const cleanup = ref<Unsubscribe | null>(null);
	const entitlementPath = collection(firestore, "Entitlement");
	const _entitlementsready = ref<boolean>(false);
	const waitingResolves: (() => void)[] = [];

	//REACTIVE
	async function setupListeners(){
		await auth.waitForReady();
		if(cleanup.value) {
			cleanup.value();
			cleanup.value = null;
		}
		usersEntitlements.value.clear();
		if(auth.authUID) {
			const entitlementsRef = query(entitlementPath, where('assignedTo', '==', auth.authUID));
			const entitlementCleanup = onSnapshot(entitlementsRef, (snap) =>{
				const tmpEntitlements = new Map<string, DataInstance<Entitlement.Model>>();
				snap.forEach(doc =>{
					const tmp: DataInstance<Entitlement.Model> = {
						$id: doc.id,
						... doc.data() as Entitlement.Model
					};
					if(tmp.status === 'active'){
						emitter.emit("entitlement:confirmed", tmp);
					}

					tmpEntitlements.set(doc.id, tmp);
				});
				usersEntitlements.value = tmpEntitlements;

				_entitlementsready.value = true;

				waitingResolves.forEach(res => res());
				waitingResolves.length = 0;
			});

			cleanup.value = entitlementCleanup;

		} else {
			console.log("Unable to setup listeners for Entitlements: User not logged in");
		}


	}

	const waitForReady = () => {
        if(_entitlementsready.value) { /* empty */ }
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

	const isEntitlementsReady = computed(() => _entitlementsready.value);

	watch(() => auth.authUID, uid => {
		setupListeners();
	}, {immediate: true});

	emitter.on('entitlement:confirmed', ()=>{
		//handle confirm here
	});

	emitter.on('entitlement:added', ()=>{
		//handle added entitlement here
	});

	emitter.on('entitlement:removed', ()=>{
		//handle removed entitlement here
	});

	const activeEntitlements = computed(() => Array.from(usersEntitlements.value.values())
		.filter((entitlement: Entitlement.Model) => (
			entitlement.status === 'active' &&
			(!!entitlement.stripeSubscriptionId || !!entitlement.transactionId) &&
			entitlement.productId === stripeConfig.subscriptionProductId
		)));

	const userIsSubscribed = computed(() => activeEntitlements.value.length > 0 || auth.isAdmin);

	const userEntitlementRenews = computed(() => {
		const latestEntitlement = activeEntitlements.value.filter((e) => e.nextRenewalDate).reduce((latestPurchase, ent) => (new Date(latestPurchase.purchaseDate).valueOf() > new Date(ent.purchaseDate).valueOf() ? latestPurchase : ent), activeEntitlements.value[0]);
		return !!latestEntitlement.nextRenewalDate;
	});

	const userEntitlementExpiresOn = computed(() => {
		const latestEntitlement = activeEntitlements.value.filter((e) => e.nextRenewalDate).reduce((latestPurchase, ent) => (new Date(latestPurchase.purchaseDate).valueOf() > new Date(ent.purchaseDate).valueOf() ? latestPurchase : ent), activeEntitlements.value[0]);
		return latestEntitlement.expirationDate;
	});

	return { isEntitlementsReady, waitForReady, emitter, userIsSubscribed, userEntitlementRenews, userEntitlementExpiresOn };
});
